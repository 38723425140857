import React, { useState } from "react";
import TextField from "@mui/material/TextField";

const SERangeValidationTextField = ({
  label,
  field,
  value,
  handleChange,
  min,
  max,
}) => {
  const [error, setError] = useState("");
  const handleValueChange = (e) => {
    const inputValue = e.target.value;

    // Allow empty string to clear the input
    if (inputValue === "") {
      setError("");
      handleChange(field, null); // Or handle it as needed, e.g., set to null
      return;
    }

    const newValue = Number(inputValue);
    if ((newValue < min || newValue > max) && newValue !== 0) {
      setError(`Value must be between ${min} and ${max}`);
    } else {
      setError("");
    }

    if ((newValue >= min && newValue <= max) || newValue === 0) {
      handleChange(field, newValue);
    }
  };

  return (
    <TextField
      key={field}
      label={label}
      type="number"
      value={value !== undefined && value !== null ? value : ""}
      onChange={handleValueChange}
      error={!!error}
      helperText={error || " "}
      fullWidth
    />
  );
};

export default SERangeValidationTextField;
