import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import React from "react";
import Draggable from "react-draggable";
import SEMinimalDatagrid from "../core/SEMinimalDatagrid";
import useControlStore from "../store/useStoreControl";
import { storeTablecols } from "../constants/config";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const StoreModal = ({ isOpen, handleClose }) => {
  const { globalSelectedStores } = useControlStore();
  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="lg"
        onClose={() => handleClose(false)}
        PaperComponent={PaperComponent}
        aria-labelledby="stores-dialog"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Selected Stores
        </DialogTitle>
        <DialogContent>
          <SEMinimalDatagrid
            rows={globalSelectedStores}
            cols={storeTablecols}
            idField="seiid"
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose(false)}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StoreModal;
