import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { previewTableCols } from "../constants/preview-table";
import useControlStore from "../store/useStoreControl";
import PreviewTable from "./PreviewTable";
import { controlNameMapping } from "../constants/config";

const PreviewComponent = () => {
  const { previewScreenData } = useControlStore();
  return (
    <>
      {Object.keys(previewScreenData).map((control) => (
        <Accordion
          id={control}
          defaultExpanded
          sx={{
            background: "linear-gradient(45deg, #f1f9f1, #66bb6a)", // Darker shade of green
            color: "black",
            borderRadius: "8px",
            marginBottom: "16px",
            "&:hover": { boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)" },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${control}-content`}
            id={`${control}-header`}
            sx={{
              color: "black",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.1rem",
              }}
            >
              {`${controlNameMapping[control]} - `}
              <span style={{ color: "#FF8C00" }}>
                {previewScreenData[control].length}
              </span>
              {`${
                previewScreenData[control].length > 1 ? " stores" : " store"
              } affected`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PreviewTable
              cols={previewTableCols[control]}
              rows={previewScreenData[control]}
              pinnedCols={["storeId", "productId"]}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default PreviewComponent;
