import { createTheme, ThemeOptions } from "@mui/material/styles";

const baseTheme = createTheme({
  palette: {
    primary: {
      main: "#008000", // Primary color
    },
    secondary: {
      main: "#dc004e", // Secondary color
    },
    background: {
      default: "#f5f5f5", // Background color
    },
    text: {
      primary: "#333333", // Primary text color
      secondary: "#666666", // Secondary text color
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
    fontSize: 14,
    h1: {
      fontSize: "2rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    body1: {
      // fontSize: "1.3rem",
    },
    body2: {
      fontSize: "1.1rem",
    },
  },
  spacing: 8, // Default spacing unit
});

const componentTheme = {
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "1.3rem", // Apply the desired font size
          fontFamily: "Roboto, Arial, sans-serif",
        },
      },
    },
    // Add font size for autocomplete
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          fontSize: "1.3rem", // Apply the desired font size for autocomplete input
          fontFamily: "Roboto, Arial, sans-serif",
        },
        // Add styles for the option text in autocomplete dropdown
        option: {
          fontSize: "1.3rem", // Apply the desired font size for option text
          fontFamily: "Roboto, Arial, sans-serif",
        },
        label: {
          fontSize: "1.3rem", // Apply the desired font size for the label
          fontFamily: "Roboto, Arial, sans-serif",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: "1.3rem", // Apply the desired font size
          fontFamily: "Roboto, Arial, sans-serif",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: "1.3rem", // Apply the desired font size
          fontFamily: "Roboto, Arial, sans-serif",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: "1.2rem", // Increase the font size as needed
          fontFamily: "Roboto, Arial, sans-serif",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          fontSize: "1rem", // Increase the font size for Tabs
          fontFamily: "Roboto, Arial, sans-serif",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "1.2rem",
          fontFamily: "Roboto, Arial, sans-serif",
        },
      },
    },
  },
};

export const themeDef: ThemeOptions = {
  ...baseTheme,
  ...componentTheme,
};
