import { Skeleton, Box } from "@mui/material";
import React, { ReactNode } from "react";

interface SESkeletonLoaderProps {
  variant?: "text" | "rectangular" | "circular";
  width?: number | string;
  height?: number | string;
  gap?: number;
  isLoading: boolean;
  children: ReactNode;
}

const SESkeletonLoader: React.FC<SESkeletonLoaderProps> = ({
  variant = "text",
  width = "100%",
  height = 60,
  gap = 1,
  isLoading,
  children,
}) => {
  // Calculate the count based on the number of children
  const count = React.Children.count(children);

  if (isLoading) {
    return (
      <Box display="flex" flexDirection="column" gap={gap}>
        {Array.from({ length: count }).map((_, index) => (
          <Skeleton
            animation="wave"
            key={index}
            variant={variant}
            width={width}
            height={height}
          />
        ))}
      </Box>
    );
  }

  // Render the wrapped content when not loading
  return <>{children}</>;
};

export default SESkeletonLoader;
