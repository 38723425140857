import { useRef, useState } from "react";
import useLocalStorage from "./useLocalStorage";

export const useResizableContainerWindowSettings = () => {
  const [storedPosition, setStoredPosition] = useLocalStorage<{
    x: number;
    y: number;
  }>("multi-store-control-size", { x: 450, y: 60 });
  const [position, setPosition] = useState(storedPosition); // Local state for position without saving to local storage every time

  const windowCoordinates = useRef(storedPosition);

  const handleOnDrag = (e, ui) => {
    const { x, y } = ui;
    setPosition({ x, y });
    setStoredPosition({ x, y }); // Save only on drag
    windowCoordinates.current = { x, y };
  };

  const toggleFullScreen = (isMaximized) => {
    if (isMaximized) {
      setPosition({ x: 10, y: 50 }); // Set fullscreen position without saving to local storage
    } else {
      setPosition(windowCoordinates.current); // Restore previous position from ref
    }
  };

  return {
    position,
    handleOnDrag,
    toggleFullScreen,
  };
};
