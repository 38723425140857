import { Autocomplete, Chip, Paper, TextField } from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";

interface AutoCompleteProps {
  limit: number;
  isMultiple: boolean;
  options: any[];
  label: string;
  isRequired: boolean;
  onChange: any;
  value: any[];
  disabled: boolean;
  size: "small" | "medium";
  valueKey: string;
  labelKey: string;
}
const RequiredLabel = ({ label, required }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <span>{label}</span>
    {required && <span style={{ color: red[500], marginLeft: 4 }}>*</span>}
  </div>
);
const SEAutoComplete: React.FC<AutoCompleteProps> = ({
  limit,
  isMultiple,
  options,
  label,
  isRequired,
  onChange,
  value,
  disabled,
  valueKey,
  labelKey,
  size = "small",
}) => {
  return (
    <>
      <Autocomplete
        limitTags={limit}
        value={value || []}
        disabled={disabled || false}
        multiple={isMultiple || false}
        ListboxProps={{
          style: {
            maxHeight: "200px", // Limit dropdown height
            overflowY: "auto", // Enable scrolling within the dropdown
          },
        }}
        options={
          isMultiple
            ? [...options]?.sort((a, b) => {
                const isASelected = value?.some(
                  (selected) => selected[valueKey] === a[valueKey]
                );
                const isBSelected = value?.some(
                  (selected) => selected[valueKey] === b[valueKey]
                );

                if (isASelected && !isBSelected) {
                  return -1; // Move 'a' to the top
                }
                if (!isASelected && isBSelected) {
                  return 1; // Move 'b' to the top
                }
                return 0; // Keep the order if both are selected or unselected
              })
            : options
        }
        getOptionLabel={(option) =>
          option?.[labelKey] ? option[labelKey] : ""
        }
        isOptionEqualToValue={(option, value) =>
          option?.[valueKey] === value?.[valueKey]
        }
        onChange={onChange}
        renderTags={(tagValue, getTagProps) => {
          // Limit number of tags rendered
          const displayedTags = tagValue.slice(0, limit); // Limit the number of displayed tags
          const overflowCount = tagValue.length - limit; // Calculate overflow count
          return (
            <>
              {displayedTags.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={index}
                  label={option[labelKey]}
                  size="medium"
                  variant="filled"
                  color="primary"
                />
              ))}
              {overflowCount > 0 && (
                <Chip
                  key="overflow"
                  label={`+${overflowCount}`} // Show the "+X" for overflowed tags
                  size="medium"
                  variant="outlined"
                  color="warning"
                />
              )}
            </>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={<RequiredLabel label={label} required={isRequired} />}
            variant="outlined"
            fullWidth
            margin="normal"
            size={size}
            // inputProps={{
            //   ...params.inputProps,
            //   style: {
            //     height: "40px", // Set a fixed height for the input box
            //     overflow: "hidden", // Hide overflow if tags extend
            //   },
            // }}
          />
        )}
      />
    </>
  );
};

export default SEAutoComplete;
