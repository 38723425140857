import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import Draggable, { ControlPosition } from "react-draggable";
import { Resizable, ResizeCallbackData } from "react-resizable";
import "react-resizable/css/styles.css";

interface ResizableDraggableContainerProps {
  size: { width: number; height: number };
  setSize: React.Dispatch<
    React.SetStateAction<{ width: number; height: number }>
  >;
  minConstraints?: [number, number];
  maxConstraints?: [number, number];
  position?: ControlPosition;
  onDrag: Function;
  children?: React.ReactNode;
}

const ResizableDraggableContainer: React.FC<
  ResizableDraggableContainerProps
> = ({
  size,
  setSize,
  minConstraints = [200, 100],
  position,
  children,
  onDrag,
}) => {
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  });

  const [adaptiveConstraints, setAdaptiveConstraints] = useState({
    min: minConstraints,
    max: [600, 400] as [number, number],
  });

  useEffect(() => {
    const updateSizeConstraints = () => {
      // Calculate adaptive constraints based on screen size
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // Minimum size should be proportional to screen size but not smaller than original minConstraints
      const adaptiveMin: [number, number] = [
        Math.max(minConstraints[0], screenWidth * 0.1), // 10% of screen width
        Math.max(minConstraints[1], screenHeight * 0.1), // 10% of screen height
      ];

      // Maximum size should be proportional to screen size
      const adaptiveMax: [number, number] = [
        screenWidth * 0.9, // 90% of screen width
        screenHeight * 0.9, // 90% of screen height
      ];

      setAdaptiveConstraints({ min: adaptiveMin, max: adaptiveMax });

      // Adjust current size if it's outside new constraints
      const newSize = {
        width: Math.min(Math.max(size.width, adaptiveMin[0]), adaptiveMax[0]),
        height: Math.min(Math.max(size.height, adaptiveMin[1]), adaptiveMax[1]),
      };

      if (newSize.width !== size.width || newSize.height !== size.height) {
        setSize(newSize);
      }
    };

    const updateBounds = () => {
      const padding = Math.min(window.innerWidth * 0.02, 20); // Adaptive padding, max 20px
      const right = Math.max(0, window.innerWidth - size.width - padding);
      const bottom = Math.max(0, window.innerHeight - size.height - padding);
      setBounds({
        left: padding,
        top: padding,
        right,
        bottom,
      });
    };

    const handleResize = () => {
      updateSizeConstraints();
      updateBounds();
    };

    // Initial setup
    handleResize();

    // Add resize listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [size, minConstraints, setSize]);

  const onResize = (
    event: React.SyntheticEvent<Element>,
    { size }: ResizeCallbackData
  ) => {
    setSize(size);
  };

  return (
    <Draggable
      handle=".draggable-handle"
      position={position}
      onDrag={(e, ui) => onDrag(e, ui)}
      bounds={bounds}
    >
      <Resizable
        width={size.width}
        height={size.height}
        onResize={onResize}
        minConstraints={adaptiveConstraints.min}
        maxConstraints={adaptiveConstraints.max}
      >
        {children}
      </Resizable>
    </Draggable>
  );
};

export default ResizableDraggableContainer;
