import { Alert, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const InfoPanel = () => {
  return (
    <>
      <Box display="flex" width="100%" alignItems="flex-start" mb={2}>
        <Alert
          severity="warning"
          icon={false} // Red icon added
          sx={{
            width: "100%",
            padding: "2px 6px", // Further reduce padding
            // fontSize: "0.75rem", // Decrease font size more
            lineHeight: "1rem", // Reduce line-height
            display: "flex", // Use flexbox for alignment
            alignItems: "center", // Align items vertically
          }}
        >
          <InfoIcon
            sx={{
              color: "orange",
              marginRight: "4px",
              // fontSize: "1rem",
              alignSelf: "center", // Center icon vertically
            }}
          />
          <span
            style={{
              display: "inline-flex",
              color: "black",
              alignItems: "center", // Center text vertically
              // lineHeight: "1rem", // Match line-height for text
              transform: "translateY(1px)", // Adjust position of text upward
            }}
          >
            Leave blank if you do not want to make any change
          </span>
        </Alert>
      </Box>
    </>
  );
};

export default InfoPanel;
