import constants from "../constants/constants.json";
import { formServiceUrl } from "../utils/apiUrlUtil";
import { createApiClient } from "./apiClient";

interface ElasticSearchResponse<T> {
  hits: {
    hits: Array<{
      _source: T;
    }>;
  };
}

const {
  urlConstants: { compositeMaster },
} = constants;

const { name: SERVICE_NAME, getStoresFromES } = compositeMaster;

const baseUrlFactory = async () => formServiceUrl(SERVICE_NAME);

const apiClient = createApiClient(baseUrlFactory);

export const fetchDataFromElastic = async <T>(
  size: number = 10
): Promise<ElasticSearchResponse<T>> => {
  return await apiClient.get<ElasticSearchResponse<T>>(
    `${getStoresFromES}?size=${size}`
  );
};
