import React, { ReactNode, useEffect, useState } from "react";
import { useGetMultiStoreSingleProductControl } from "../hooks/controlConfigApiHooks";
import { IStore, ProductInfo } from "../interfaces/IStore";
import {
  mapMaxIncDecToTableData,
  mapMinMaxPriceToTableData,
} from "../utils/mapper";
import SEBulkEditingDataGrid from "../core/SEBulkEditingDataGrid";
import {
  GridApi,
  GridRowId,
  GridRowModes,
  GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid";
import { controlTabTableCols } from "../constants/config";
import SESkeletonLoader from "../core/SESkeletonLoader";
import useControlStore from "../store/useStoreControl";

interface DataPanelProps {
  selectedStores: string[];
  selectedProduct: string;
  children: ReactNode;
  tabId: string;
  processRowUpdate: any;
}

const DataPanel: React.FC<DataPanelProps> = ({
  children,
  selectedStores,
  selectedProduct,
  tabId,
  processRowUpdate,
}) => {
  const apiRef = useGridApiRef();
  const {
    originalMaxIncDec,
    originalMinMaxPrice,
    editedMinMaxPrice,
    editedMaxIncDec,
    editedMinMaxRowMeta,
    editedMaxIncDecRowMeta,
    setMinMaxPriceData,
    setMaxIncData,
    updateMinMaxPrice,
    updateMaxIncDec,
    globalSelectedStores,
    resetControlData,
  } = useControlStore();

  const {
    data: fetchedData,
    error: fetchError,
    isLoading: fetchLoading,
    isFetching,
    refetch,
  } = useGetMultiStoreSingleProductControl(selectedStores, selectedProduct);

  // State for dynamic height
  const [gridHeight, setGridHeight] = useState(window.innerHeight * 0.5); // Initial height (50% of viewport)

  // Handle product changes
  useEffect(() => {
    const handleProductChange = async () => {
      if (selectedProduct) {
        console.log("Product changed to:", selectedProduct);
        resetControlData();
        await refetch();
      }
    };

    handleProductChange();
  }, [selectedProduct]); // Only depend on selectedProduct

  // Update store data when fetch completes
  useEffect(() => {
    if (fetchedData && !isFetching) {
      console.log("Setting new data for product:", selectedProduct);
      setMinMaxPriceData(
        fetchedData.minMaxPrice.map((data) =>
          mapMinMaxPriceToTableData(data, globalSelectedStores)
        )
      );
      setMaxIncData(
        fetchedData.maxIncDec.map((data) =>
          mapMaxIncDecToTableData(data, globalSelectedStores)
        )
      );
    }
  }, [fetchedData, isFetching]); // Remove selectedProduct dependency

  // Show loading state while fetching
  const isLoadingData = fetchLoading || isFetching;

  // Handle fetch errors
  useEffect(() => {
    if (fetchError) {
      console.error(
        "Error fetching data for product:",
        selectedProduct,
        fetchError
      );
    }
  }, [fetchError]);

  const [rowModesModel, setRowModesModel] = useState({});

  // Function to update grid height based on window size
  const updateGridHeight = () => {
    console.log("height -- ", window.innerHeight * 0.5);

    const newHeight = Math.min(window.innerHeight * 0.5, 470); // Minimum height of 300px
    setGridHeight(newHeight);
  };

  useEffect(() => {
    // Set initial height
    updateGridHeight();

    // Add resize listener
    window.addEventListener("resize", updateGridHeight);

    // Cleanup
    return () => window.removeEventListener("resize", updateGridHeight);
  }, []);

  const getControlData = (tabId: string) => {
    if (tabId === "minMaxPrice") {
      return editedMinMaxPrice;
    } else if (tabId === "maxIncDec") {
      return editedMaxIncDec;
    }
    return [];
  };

  const getSelectedRows = (tabId: string) => {
    if (tabId === "minMaxPrice") {
      return Object.keys(editedMinMaxRowMeta);
    } else if (tabId === "maxIncDec") {
      return Object.keys(editedMaxIncDecRowMeta);
    }
    return [];
  };

  const getControlDataOriginalRow = (tabId: string, id: string) => {
    if (tabId === "minMaxPrice") {
      return originalMinMaxPrice.find((val) => val.id === id);
    } else {
      return originalMaxIncDec.find((val) => val.id === id);
    }
  };

  const handleRowEditClick = React.useCallback(
    (id: GridRowId) => {
      console.log(tabId);

      // Get the row data
      const row = apiRef.current.getRow(id);
      setRowModesModel({
        ...rowModesModel,
        [tabId]: {
          ...rowModesModel[tabId],
          [id]: { mode: GridRowModes.Edit },
        },
      });
    },
    [tabId, rowModesModel]
  );

  const handleRowEditSave = React.useCallback(
    (id: GridRowId) => {
      // Stop editing the row and save changes
      setRowModesModel({
        ...rowModesModel,
        [tabId]: {
          ...rowModesModel[tabId],
          [id]: { mode: GridRowModes.View },
        },
      });
      const row = apiRef.current.getRow(id);
      processRowUpdate(row);
      // Optionally trigger a save action here if needed
    },
    [apiRef, processRowUpdate, tabId, rowModesModel]
  );

  const handleDiscardButtonClick = React.useCallback(
    (id) => {
      const originalRow = getControlDataOriginalRow(tabId, id);
      if (tabId === "minMaxPrice") {
        updateMinMaxPrice(id, originalRow, []);
      } else {
        updateMaxIncDec(id, originalRow, []);
      }
    },
    [tabId, editedMinMaxPrice, editedMaxIncDec]
  );

  const onRowSelectionToggle = React.useCallback(
    (newSelection: GridRowSelectionModel) => {
      //   const tabId = validationTabs[validationTabValue].id;
      //   const selectedIds = newSelection;
      //   const previouslySelectedIds = selectedRows[tabId] || [];
      //   const removedIds = previouslySelectedIds.filter(
      //     (id) => !selectedIds.includes(id)
      //   );
      //   removedIds.forEach((id) => handleDiscardButtonClick(id));
    },
    []
  );

  const getRowClassName = React.useCallback(
    ({ id }) => {
      if (tabId === "minMaxPrice") {
        return editedMinMaxRowMeta[id] ? "row--edited" : "";
      } else {
        return editedMaxIncDecRowMeta[id] ? "row--edited" : "";
      }
    },
    [tabId, editedMinMaxRowMeta, editedMaxIncDecRowMeta]
  );

  const getUnsavedRowIds = (tabId: string) => {
    if (tabId === "minMaxPrice") {
      return Object.keys(editedMinMaxRowMeta);
    } else if (tabId === "maxIncDec") {
      return Object.keys(editedMaxIncDecRowMeta);
    }
    return [];
  };

  const getCellClassName = (params) => {
    const { id, field } = params;
    if (tabId === "minMaxPrice") {
      return editedMinMaxRowMeta?.[id] &&
        editedMinMaxRowMeta[id].includes(field)
        ? "cell--edited"
        : "";
    } else if (tabId == "maxIncDec") {
      return editedMaxIncDecRowMeta?.[id] &&
        editedMaxIncDecRowMeta[id].includes(field)
        ? "cell--edited"
        : "";
    }
    return "";
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      {/* Default DataGrid toolbar buttons */}
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      {/* Alert for selected product */}
      {/* <Chip
        variant="filled"
        color="warning"
        icon={<LabelImportantIcon />}
        label={selectedProduct?.displayname}
        sx={{
          width: "fit-content", // Use fit-content for content size or a specific width
          minWidth: "120px", // Minimum width to ensure it stretches a bit
          padding: "6px 12px", // Adjust padding for visual stretching
          display: "flex", // Use flexbox to help stretch
          justifyContent: "center", // Center the label
        }}
      /> */}

      {/* This pushes the button to the right */}
      {/* <Box sx={{ flexGrow: 1 }} /> */}

      {/* Maximize/Minimize Button */}
      {/* <IconButton
        onClick={toggleMaximize}
        edge="end"
        color="primary"
        sx={{ margin: "0 8px" }}
      >
        <Tooltip title={isMaximized ? "Exit Full Screen" : "Full Screen"}>
          {isMaximized ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </Tooltip>
      </IconButton> */}
    </GridToolbarContainer>
  );

  return (
    <>
      {children}
      <SESkeletonLoader
        isLoading={isLoadingData}
        variant="rectangular"
        width="100%"
        height={90}
      >
        {fetchError ? (
          <div>Error loading data. Please try again.</div>
        ) : (
          <div style={{ height: gridHeight }}>
            {" "}
            {/* Use dynamic height */}
            <SEBulkEditingDataGrid
              apiRef={apiRef}
              cols={controlTabTableCols[tabId]}
              rows={getControlData(tabId)}
              unsavedRows={getUnsavedRowIds(tabId)}
              isSaving={isLoadingData}
              processRowUpdate={processRowUpdate}
              getRowClassName={getRowClassName}
              getCellClassName={getCellClassName}
              handleEditClick={handleRowEditClick}
              handleSaveClick={handleRowEditSave}
              rowModesModel={rowModesModel?.[tabId] || {}}
              setRowModesModel={setRowModesModel}
              selectedRows={getSelectedRows(tabId)}
              handleDiscardButtonClick={handleDiscardButtonClick}
              onRowSelectionToggle={onRowSelectionToggle}
              toolbar={CustomToolbar}
            />
          </div>
        )}
      </SESkeletonLoader>
    </>
  );
};

export default DataPanel;
