import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/material";

interface SEDialogProps {
  open: boolean;
  title: string;
  children: React.ReactNode;
  footer?: React.ReactNode[];
  onClose: () => void;
}

const SEDialog: React.FC<SEDialogProps> = ({
  open,
  title,
  children,
  footer,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: { borderRadius: 3, p: 1.5, border: "2px solid green" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            color: "text.primary",
            p: 0,
          }}
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            ml: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent dividers sx={{ py: 1.5, px: 2 }}>
        {children}
      </DialogContent>
      {footer && (
        <>
          <Divider />
          <DialogActions sx={{ px: 2, py: 1 }}>
            {footer.map((cta, index) => (
              <React.Fragment key={index}>{cta}</React.Fragment>
            ))}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default SEDialog;
