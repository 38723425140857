import { Box, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { usePricegenStoreFromES } from "../hooks/elasticDataApiHooks";
import SEAutoComplete from "../core/SEAutoComplete";
import products from "../constants/products.json";
import { ProductInfo } from "../interfaces/IStore";
import SESkeletonLoader from "../core/SESkeletonLoader";
import useControlStore from "../store/useStoreControl";

const SelectionPanel = ({
  selectedStoreIdList = [],
  isMaximized = false,
  selectedProduct,
  setSelectedProduct,
}) => {
  const [stores, setStores] = useState([]);
  // const { data, error, isLoading } = usePricegenStoreFromES();
  const productList: ProductInfo[] = useMemo(() => products, []);

  const {
    globalSelectedStores,
    setGlobalSelectedStores,
    setSelectedProduct: setGlobalSelectedProduct,
    editedMaxIncDecRowMeta,
    editedMinMaxRowMeta,
    globalMinMaxPriceValues,
    globalMaxIncDecValues,
  } = useControlStore();

  const hasUnsavedChanges =
    Object.keys(editedMaxIncDecRowMeta).length > 0 ||
    Object.keys(editedMinMaxRowMeta).length > 0 ||
    Object.keys(globalMinMaxPriceValues).length > 0 ||
    Object.keys(globalMaxIncDecValues).length > 0;

  const disabledTooltip =
    "Please save or discard your changes before selecting a different store or product";

  useEffect(() => {
    setStores(globalSelectedStores);
    // if (data && selectedStoreIdList && selectedStoreIdList.length > 0) {
    //   const filteredStores = data.filter((store) =>
    //     selectedStoreIdList.includes(store.importcode)
    //   );

    //   if (JSON.stringify(filteredStores) !== JSON.stringify(stores)) {
    //     setGlobalSelectedStores(filteredStores);
    //     setStores(filteredStores);
    //   }
    // }
  }, [globalSelectedStores]);

  const handleStoreSelection = (event, value) => {
    if (!hasUnsavedChanges) {
      setGlobalSelectedStores(value);
    }
  };

  const handleProductSelection = (event, value) => {
    if (!hasUnsavedChanges) {
      setSelectedProduct(value);
      setGlobalSelectedProduct(value);
    }
  };

  // if (error) {
  //   return <div>Error loading data: {error.message}</div>;
  // }

  return (
    <>
      {/* Stores AutoComplete */}
      <Box
        flex={1}
        minWidth="250px"
        display="flex"
        flexDirection="column"
        gap={1}
      >
        <Tooltip title={hasUnsavedChanges ? disabledTooltip : ""}>
          <div>
            {/* Wrapper div needed for Tooltip to work with disabled elements */}
            <SEAutoComplete
              label="Select Stores"
              limit={isMaximized ? 2 : 1}
              isMultiple={true}
              options={stores}
              disabled={hasUnsavedChanges}
              isRequired={true}
              onChange={handleStoreSelection}
              size="medium"
              value={globalSelectedStores}
              labelKey="displayname"
              valueKey="importcode"
            />
          </div>
        </Tooltip>
        {/* <SESkeletonLoader
          variant="text"
          width="100%"
          height={90}
          isLoading={isLoading}
        >
          <Tooltip title={hasUnsavedChanges ? disabledTooltip : ""}>
            <div>
              <SEAutoComplete
                label="Select Stores"
                limit={isMaximized ? 2 : 1}
                isMultiple={true}
                options={stores}
                disabled={hasUnsavedChanges}
                isRequired={true}
                onChange={handleStoreSelection}
                size="medium"
                value={globalSelectedStores}
                labelKey="displayname"
                valueKey="importcode"
              />
            </div>
          </Tooltip>
        </SESkeletonLoader> */}
      </Box>

      {/* Products AutoComplete */}
      <Box
        flex={1}
        minWidth="250px"
        display="flex"
        flexDirection="column"
        gap={1}
      >
        <Tooltip title={hasUnsavedChanges ? disabledTooltip : ""}>
          <div>
            <SEAutoComplete
              label="Select Product"
              limit={isMaximized ? 3 : 1}
              isMultiple={false}
              options={productList}
              disabled={hasUnsavedChanges}
              isRequired={true}
              onChange={handleProductSelection}
              size="medium"
              value={selectedProduct}
              labelKey="name"
              valueKey="id"
            />
          </div>
        </Tooltip>
        {/* <SESkeletonLoader
          variant="text"
          width="100%"
          height={90}
          isLoading={isLoading}
        >
          <Tooltip title={hasUnsavedChanges ? disabledTooltip : ""}>
            <div>
              <SEAutoComplete
                label="Select Product"
                limit={isMaximized ? 3 : 1}
                isMultiple={false}
                options={productList}
                disabled={hasUnsavedChanges}
                isRequired={true}
                onChange={handleProductSelection}
                size="medium"
                value={selectedProduct}
                labelKey="name"
                valueKey="id"
              />
            </div>
          </Tooltip>
        </SESkeletonLoader> */}
      </Box>
    </>
  );
};

export default SelectionPanel;
