import { formServiceUrl } from "../utils/apiUrlUtil";
import { createApiClient } from "./apiClient";
import constants from "../constants/constants.json";
import { IControlConfigPatchRequest } from "../interfaces/IControlConfigPatchRequest";
import { IControlConfigPatchResponse } from "../interfaces/IControlConfigPatchResponse";

const {
  urlConstants: { priceAdminMaster },
} = constants;

const { patchControlsInBulk, name: SERVICE_NAME } = priceAdminMaster;

const baseUrlFactory = async () => formServiceUrl(SERVICE_NAME);
const apiClient = createApiClient(baseUrlFactory);

export const patchControlsForMultipleStores = (
  data: IControlConfigPatchRequest[]
) => {
  return apiClient.patch<IControlConfigPatchResponse[]>(
    patchControlsInBulk,
    data
  );
};
