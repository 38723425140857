// In single-spa, the assets need to be loaded from a dynamic location,
// instead of hard coded to `/assets`. We use webpack public path for this.
// See https://webpack.js.org/guides/public-path/#root

export function assetUrl(url: string): string {
  // @ts-ignore
  const publicPath = getAssetDomain();
  const publicPathSuffix = publicPath.endsWith("/") ? "" : "/";
  const urlPrefix = url.startsWith("/") ? "" : "/";

  return `${publicPath}${publicPathSuffix}assets${urlPrefix}${url}`;
}

const getAssetDomain = () => {
  const hostname = window && window.location && window.location.hostname;

  if (/^.*localhost.*/.test(hostname)) {
    return "http://localhost:8080";
  } else if (/^visops-dev.7-eleven.com/.test(hostname)) {
    return "https://api.7ep-dev.7-eleven.com/fuels/pricing/visops-multi-store-control-config/";
  } else if (/^visops-test.7-eleven.com/.test(hostname)) {
    return "https://api.7ep-test.7-eleven.com/fuels/pricing/visops-multi-store-control-config/";
  } else {
    return "https://api.7ep.7-eleven.com/fuels/pricing/visops-multi-store-control-config/";
  }
};
