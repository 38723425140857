import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { IStore } from "../interfaces/IStore";
import { fetchDataFromElastic } from "../api/esDataApi";

// const { setIsLoadingStores } = useControlStore();
export const usePricegenStoreFromES = (): UseQueryResult<IStore[], Error> => {
  const fetchPriceGenStoreFromES = async (): Promise<IStore[]> => {
    const response = await fetchDataFromElastic<IStore>(100);
    const data = response.hits.hits.map((hit) => hit._source);
    // setIsLoadingStores(false);
    return data;
  };
  return useQuery<IStore[], Error>(
    ["elasticsearchData"],
    fetchPriceGenStoreFromES,
    {
      retry: 2,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
};
