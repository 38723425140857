import React, { useEffect, useState } from "react";
import ResizableDraggableContainer from "./ResizableDraggableContainer";
import { Box, CssBaseline, Paper, ThemeProvider } from "@mui/material";
import "react-resizable/css/styles.css";
import { useResizableContainerWindowSettings } from "../hooks/useResizableContainerWindowSettings";
import AppHeader from "./AppHeader";
import AppContent from "./AppContent";
import StoreModal from "./StoreModal";
import ConfirmationModal from "./ConfirmationModal";
import SELoaderWithBackdrop from "../core/SELoaderWithBackDrop";
import useControlStore from "../store/useStoreControl";
import { IControlConfigPatchRequest } from "../interfaces/IControlConfigPatchRequest";
import {
  mapMaxIncDecChanges,
  mapMinMaxPriceChanges,
  transformControlConfigChangesList,
} from "../utils/mapper";
import { usePatchBulkControlConfig } from "../hooks/controlConfigApiHooks";
import { useSnackbar } from "notistack";
import PreviewComponent from "./PreviewComponent";
import { themeDef } from "../theme/theme";
import { ProductInfo } from "../interfaces/IStore";
import { getUserName } from "../utils/app-utils";
import EmptyStateScreen from "./EmptyStateScreen";
import { usePricegenStoreFromES } from "../hooks/elasticDataApiHooks";

interface RootProps {
  listviewSelectedStores: string[];
  extractedStoreCount: number;
}

interface ControlChangesMeta {
  id: string;
  maxIncDec: string[];
  minMaxPrice: string[];
}

const RootComponent = ({ customProps }) => {
  console.log("Cutom props MSE -- ", customProps);

  const [rootProps, setRootProps] = useState<RootProps>();
  const [noStoreMessage, setNoStoreMessage] = useState({
    title: "",
    body: "",
  });
  const [isStoreModalOpen, setStoreModalOpen] = useState(false);
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 1200,
    height: 180, // Initial compact height
  });
  const [isMaximized, setIsMaximized] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductInfo>();
  const { handleOnDrag, position, toggleFullScreen } =
    useResizableContainerWindowSettings();
  const { mutate } = usePatchBulkControlConfig();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isSubmitting,
    editedMaxIncDecRowMeta,
    editedMinMaxRowMeta,
    editedMinMaxPrice,
    editedMaxIncDec,
    setIsSubmitting,
    setPreviewScreenData,
    openPreviewScreen,
    setOpenPreviewScreen,
    resetStore,
    globalSelectedStores,
    setGlobalSelectedStores,
  } = useControlStore();
  const { data, error, isLoading } = usePricegenStoreFromES();

  const toggleMaximize = () => {
    if (!isMaximized) {
      setSize({
        width: window.innerWidth - 45,
        height: window.innerHeight - 155,
      });
      toggleFullScreen(!isMaximized);
    } else {
      setSize({ width: 1200, height: 780 });
      toggleFullScreen(!isMaximized);
    }
    setIsMaximized(!isMaximized);
  };

  useEffect(() => {
    const subscription =
      customProps?.compRef?.mapLayerService?.openMultiStoreControlConfigWindow$?.subscribe(
        (data: RootProps) => {
          console.log("MSE --> ", data);

          setRootProps(data);
        }
      );
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    // Log to debug
    console.log("Selected Product:", selectedProduct);

    if (selectedProduct?.id) {
      // Check for specific property to ensure product is selected
      setSize((prev) => ({ ...prev, height: 780 }));
    } else {
      setSize((prev) => ({ ...prev, height: 180 }));
    }
  }, [selectedProduct]);

  // Function to merge and flatten the changes by ID
  const mergeAndFlattenChanges = (
    minMaxPrice,
    maxIncDec
  ): ControlChangesMeta[] => {
    const result = [];

    // Add minMaxPrice changes to the result
    for (const [id, fields] of Object.entries(minMaxPrice)) {
      let existingEntry = result.find((entry) => entry.id === id);
      if (!existingEntry) {
        existingEntry = { id, minMaxPrice: [], maxIncDec: [] };
        result.push(existingEntry);
      }
      existingEntry.minMaxPrice = fields;
    }

    // Add maxIncDec changes to the result
    for (const [id, fields] of Object.entries(maxIncDec)) {
      let existingEntry = result.find((entry) => entry.id === id);
      if (!existingEntry) {
        existingEntry = { id, minMaxPrice: [], maxIncDec: [] };
        result.push(existingEntry);
      }
      existingEntry.maxIncDec = fields;
    }

    return result;
  };
  const patchControlConfigChanges = () => {
    setConfirmationOpen(false);
    setIsSubmitting(true);
    const changesMeta: ControlChangesMeta[] = mergeAndFlattenChanges(
      editedMinMaxRowMeta,
      editedMaxIncDecRowMeta
    );
    const finalControlPatchObject: IControlConfigPatchRequest[] =
      changesMeta.map((change) => {
        const { id, maxIncDec, minMaxPrice } = change;

        const requestObject: IControlConfigPatchRequest = {
          storeId: undefined,
          productId: selectedProduct?.id,
          lastUpdatedBy: getUserName(),
        };
        if (maxIncDec && maxIncDec.length) {
          const editedRow = editedMaxIncDec.find((row) => row.id === id);
          if (!requestObject.storeId) {
            requestObject.storeId = editedRow.store;
          }
          const changedObj = {
            action_on_failure_new: undefined,
            enabled_new: undefined,
            max_increase_new: undefined,
            max_decrease_new: undefined,
          };
          maxIncDec.forEach((field) => (changedObj[field] = editedRow[field]));
          const mappedMaxIncDecChanges = mapMaxIncDecChanges(changedObj);
          requestObject.maxIncDecControlConfigs = mappedMaxIncDecChanges;
        }

        if (minMaxPrice && minMaxPrice.length) {
          const editedRow = editedMinMaxPrice.find((row) => row.id === id);
          if (!requestObject.storeId) {
            requestObject.storeId = editedRow.store;
          }
          const changedObj = {
            action_on_failure_new: undefined,
            enabled_new: undefined,
            min_price_new: undefined,
            max_price_new: undefined,
          };
          minMaxPrice.forEach(
            (field) => (changedObj[field] = editedRow[field])
          );
          const mappedMinMaxChanges = mapMinMaxPriceChanges(changedObj);
          requestObject.minMaxPriceControlConfigs = mappedMinMaxChanges;
        }
        return requestObject;
      });
    console.log(finalControlPatchObject);

    mutate(finalControlPatchObject, {
      onSuccess: (data) => {
        const previewScreenData = transformControlConfigChangesList(data);
        enqueueSnackbar("Control configs updated successfully", {
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
          autoHideDuration: 6000,
        });
        setPreviewScreenData(previewScreenData);
        setIsSubmitting(false);
        setOpenPreviewScreen(true);
      },
      onError(error, variables, context) {
        console.error(error);
        enqueueSnackbar(
          "Unable to apply the bulk configuration updates. Please check for any issues and try again.",
          {
            variant: "error",
            anchorOrigin: { horizontal: "right", vertical: "top" },
            autoHideDuration: 6000,
          }
        );
      },
    });
  };

  useEffect(() => {
    if (selectedProduct) {
      setSize((prev) => ({ ...prev, height: 780 }));
    } else {
      setSize((prev) => ({ ...prev, height: 300 }));
    }
  }, [selectedProduct]);

  // const hasSelectedStores = rootProps?.listviewSelectedStores?.length > 0;
  useEffect(() => {
    console.log(
      "List view selected stores --> ",
      rootProps?.listviewSelectedStores
    );

    if (rootProps?.listviewSelectedStores?.length <= 0) {
      setNoStoreMessage({
        title: "No Stores Selected from list",
        body: "Please select one or more PriceGen stores to configure control settings",
      });
    } else {
      if (
        data &&
        rootProps?.listviewSelectedStores &&
        rootProps?.listviewSelectedStores?.length > 0
      ) {
        console.log("ES Data ---> ", data);

        const filteredStores = data.filter(
          (store) =>
            rootProps?.listviewSelectedStores?.includes(store.importcode) &&
            store.seiid
        );
        if (filteredStores?.length > 0) {
          setGlobalSelectedStores(filteredStores);
        } else {
          setNoStoreMessage({
            title: "No PriceGen stores selected",
            body: "Please select one or more PriceGen stores to configure control settings",
          });
        }
      }
    }
  }, [rootProps?.listviewSelectedStores, data]);

  return (
    <>
      <ThemeProvider theme={themeDef}>
        <CssBaseline />
        <ResizableDraggableContainer
          size={size}
          setSize={setSize}
          minConstraints={[1200, selectedProduct?.id ? 780 : 180]}
          maxConstraints={[window.innerWidth - 50, window.innerHeight - 50]}
          position={position}
          onDrag={handleOnDrag}
        >
          <Paper
            style={{
              position: "relative",
              flexGrow: 1,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              height: size.height,
              width: size.width,
              cursor: "default",
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0,0,0,0.12), 0 0 1px rgba(0,0,0,0.1)",
              transition: "height 0.3s ease-in-out",
            }}
          >
            <Box className="draggable-handle">
              <AppHeader
                toggleMaximize={toggleMaximize}
                isMaximized={isMaximized}
                openStoreModal={setStoreModalOpen}
                setConfirmationOpen={setConfirmationOpen}
                showChips={!openPreviewScreen}
                closeApp={() => {
                  resetStore();
                  customProps.compRefClose();
                }}
                setIsUnsavedChanges={setIsUnsavedChanges}
              />
            </Box>
            {!(globalSelectedStores.length > 0) && !openPreviewScreen ? (
              <>
                <SELoaderWithBackdrop open={isLoading} />
                <EmptyStateScreen
                  openStoreModal={() => setStoreModalOpen(true)}
                  noStoreMessage={noStoreMessage}
                />
              </>
            ) : openPreviewScreen ? (
              <Box
                sx={{
                  flex: 1,
                  overflow: "auto",
                  padding: "16px",
                  "& > .MuiStack-root": {
                    padding: "0 8px",
                    marginBottom: "16px",
                  },
                }}
              >
                <PreviewComponent />
              </Box>
            ) : (
              <>
                <SELoaderWithBackdrop open={isSubmitting} />
                <Box
                  sx={{
                    flex: 1,
                    overflow: "hidden",
                    padding: "16px",
                    "& > .MuiStack-root": {
                      padding: "0 8px",
                      marginBottom: "16px",
                    },
                  }}
                >
                  <AppContent
                    selectedStoreIdList={rootProps?.listviewSelectedStores}
                    isMaximized={isMaximized}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                  />
                </Box>
              </>
            )}
          </Paper>
        </ResizableDraggableContainer>
        <StoreModal isOpen={isStoreModalOpen} handleClose={setStoreModalOpen} />
        <ConfirmationModal
          isOpen={isConfirmationOpen}
          setConfirmationOpen={setConfirmationOpen}
          patchControlConfigChanges={patchControlConfigChanges}
          isUnsavedChanges={isUnsavedChanges}
        />
      </ThemeProvider>
    </>
  );
};

export default RootComponent;
