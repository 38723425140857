import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import React, { useState } from "react";

const SEMinimalDatagrid = ({ rows, cols, idField }) => {
  const [density, setDensity] = useState("compact");
  // Custom toolbar without export button
  const CustomGridToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };
  return (
    <div style={{ height: 500, width: "100%" }}>
      <div style={{ height: "100%", overflow: "auto" }}>
        <DataGrid
          getRowId={(row) => row?.[idField]}
          density="compact"
          columns={cols}
          rows={rows}
          slots={{ toolbar: CustomGridToolbar }}
          onDensityChange={(newDensity) => setDensity(newDensity)}
          hideFooter
          style={{ height: "100%", width: "100%" }}
        />
      </div>
    </div>
  );
};

export default SEMinimalDatagrid;
