import { MaterialReactTable } from "material-react-table";
import React from "react";

const PreviewTable = ({ cols, rows, pinnedCols }) => {
  return (
    <MaterialReactTable
      columns={cols}
      data={rows}
      enableColumnPinning
      enableGrouping
      enableBottomToolbar={false}
      enableRowVirtualization
      enableStickyHeader
      enablePagination={false}
      muiTableContainerProps={{ sx: { maxHeight: "calc(100vh - 230px)" } }}
      initialState={{ density: "compact" }}
      columnFilterDisplayMode="popover"
    />
  );
};

export default PreviewTable;
