import { isEmpty } from "lodash";
import {
  MaxIncDecrease,
  MaxIncDecTable,
  MinMaxPrice,
  MinMaxPriceTable,
} from "../interfaces/IControlConfig";
import {
  MaxIncDecControlConfig,
  MinMaxControlConfig,
} from "../interfaces/IControlConfigPatchRequest";
import { IControlConfigPatchResponse } from "../interfaces/IControlConfigPatchResponse";
import { IControlPreviewChanges } from "../interfaces/IControlPreviewChanges";
import { IStore } from "../interfaces/IStore";
import Products from "../constants/products.json";

export const mapMinMaxPriceToTableData = (
  minMaxPrice: MinMaxPrice,
  storeList: IStore[]
): MinMaxPriceTable => {
  return {
    id: storeList.find((store) => store.seiid === minMaxPrice.store_id).seiid,
    store: minMaxPrice.store_id,
    min_price_old: minMaxPrice.min_price,
    min_price_new: minMaxPrice.min_price,
    max_price_old: minMaxPrice.max_price,
    max_price_new: minMaxPrice.max_price,
    enabled_old: minMaxPrice.enabled ? "Enabled" : "Disabled",
    enabled_new: minMaxPrice.enabled ? "Enabled" : "Disabled",
    action_on_failure_old: minMaxPrice.action_on_failure,
    action_on_failure_new: minMaxPrice.action_on_failure,
  };
};

export const mapMaxIncDecToTableData = (
  maxIncDec: MaxIncDecrease,
  storeList: IStore[]
): MaxIncDecTable => {
  return {
    id: storeList.find((store) => store.seiid === maxIncDec.store_id).seiid,
    store: maxIncDec.store_id,
    max_increase_old: maxIncDec.max_increase,
    max_increase_new: maxIncDec.max_increase,
    max_decrease_old: maxIncDec.max_decrease,
    max_decrease_new: maxIncDec.max_decrease,
    enabled_old: maxIncDec.enabled ? "Enabled" : "Disabled",
    enabled_new: maxIncDec.enabled ? "Enabled" : "Disabled",
    action_on_failure_old: maxIncDec.action_on_failure,
    action_on_failure_new: maxIncDec.action_on_failure,
  };
};

export const mapMinMaxPriceChanges = ({
  action_on_failure_new,
  enabled_new,
  min_price_new,
  max_price_new,
}): MinMaxControlConfig => {
  const data = {
    validationType: "PriceFallUnderMinMax",
  };

  if (action_on_failure_new) {
    data["onFailAction"] = action_on_failure_new;
  }

  if (enabled_new) {
    const enabledObj = {
      flag: enabled_new.toLowerCase() === "enabled" ? true : false,
    };
    data["enabled"] = enabledObj;
  }

  const validationDataObj = {};
  if (min_price_new) {
    validationDataObj["minPrice"] = min_price_new;
  }

  if (max_price_new) {
    validationDataObj["maxPrice"] = max_price_new;
  }

  if (!isEmpty(validationDataObj)) {
    data["validationData"] = validationDataObj;
  }

  return data;
};

export const mapMaxIncDecChanges = ({
  action_on_failure_new,
  enabled_new,
  max_increase_new,
  max_decrease_new,
}): MaxIncDecControlConfig => {
  const data = {
    validationType: "MaxIncreaseDecrease",
  };

  if (action_on_failure_new) {
    data["onFailAction"] = action_on_failure_new;
  }

  if (enabled_new) {
    const enabledObj = {
      flag: enabled_new.toLowerCase() === "enabled" ? true : false,
    };
    data["enabled"] = enabledObj;
  }

  const validationDataObj = {};
  if (max_increase_new) {
    validationDataObj["maxIncrease"] = max_increase_new;
  }

  if (max_decrease_new) {
    validationDataObj["maxDecrease"] = max_decrease_new;
  }

  if (!isEmpty(validationDataObj)) {
    data["validationData"] = validationDataObj;
  }

  return data;
};

export function transformControlConfigChangesList(
  dataList: IControlConfigPatchResponse[]
): IControlPreviewChanges {
  return dataList.reduce(
    (result, { storeId, productId, controlConfigChanges }) => {
      const product = Products.find((prod) => prod.id === productId).name;
      // Process each control configuration change
      for (const [controlName, changes] of Object.entries(
        controlConfigChanges
      )) {
        if (!result[controlName]) {
          result[controlName] = [];
        }

        // Transform each change into a nested structure with before and after values
        const transformedChange = changes.reduce(
          (acc, { property, before, after }) => {
            if (property === "enabled") {
              acc[property] = {
                before: before ? "Enabled" : "Disabled",
                after: after ? "Enabled" : "Disabled",
              };
            } else {
              acc[property] = { before, after };
            }
            return acc;
          },
          {}
        );

        // Add the transformed change along with storeId and productId
        result[controlName].push({
          storeId,
          productId: product,
          ...transformedChange,
        });
      }

      return result;
    },
    {}
  );
}
