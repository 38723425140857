import * as React from "react";
import {
  DataGrid,
  GridActionsCellItem,
  gridClasses,
  GridRowModes,
  GridToolbar,
  GridColDef,
  GridRowId,
  GridRowModesModel,
  GridRowsProp,
  GridRowSelectionModel,
  GridValidRowModel,
} from "@mui/x-data-grid";

import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import RestoreIcon from "@mui/icons-material/Restore";
import { darken } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import useControlStore from "../store/useStoreControl";
import { useEffect, useRef, useState } from "react";

interface SEBulkEditingDataGridProps {
  rows: GridRowsProp;
  cols: GridColDef[];
  apiRef: any; // Consider specifying a more precise type if possible
  unsavedRows: any[];
  isSaving: boolean;
  processRowUpdate: (
    newRow: GridValidRowModel,
    oldRow: GridValidRowModel
  ) => GridValidRowModel;
  getRowClassName: (params: any) => string;
  getCellClassName: (params: any) => string;
  handleEditClick: (id: GridRowId) => void;
  handleSaveClick: (id: GridRowId) => void;
  rowModesModel: GridRowModesModel;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  selectedRows: GridRowSelectionModel;
  handleDiscardButtonClick: (id: GridRowId) => void;
  onRowSelectionToggle: (selectionModel: GridRowSelectionModel) => void;
  toolbar: any;
}

const SEBulkEditingDataGrid: React.FC<SEBulkEditingDataGridProps> = ({
  rows,
  cols,
  apiRef,
  unsavedRows,
  isSaving,
  processRowUpdate,
  getRowClassName,
  getCellClassName,
  handleEditClick,
  handleSaveClick,
  rowModesModel,
  setRowModesModel,
  selectedRows,
  handleDiscardButtonClick,
  onRowSelectionToggle,
  toolbar,
}) => {
  // Clear rowModesModel when switching tabs
  React.useEffect(() => {
    // Reset rowModesModel when the tab changes
    setRowModesModel({});
  }, [setRowModesModel]);

  const columns: GridColDef[] = React.useMemo(
    () => [
      ...cols,
      {
        field: "actions",
        type: "actions",
        getActions: ({ id }: { id: GridRowId }) => {
          const isInEditMode = rowModesModel?.[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                key="save"
                icon={<DoneIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)} // Save changes
              />,
            ];
          }

          return [
            <GridActionsCellItem
              key="edit"
              icon={
                <Tooltip title="Edit Row">
                  <EditIcon color="success" />
                </Tooltip>
              }
              label="Edit"
              onClick={() => handleEditClick(id)} // Enter edit mode
            />,
            <GridActionsCellItem
              key="discard"
              icon={
                <Tooltip title="Discard Changes">
                  <RestoreIcon />
                </Tooltip>
              }
              color="warning"
              label="Discard changes"
              disabled={!unsavedRows.includes(id)}
              onClick={() => handleDiscardButtonClick(id)}
            />,
          ];
        },
      },
    ],
    [
      cols,
      rowModesModel,
      unsavedRows,
      handleSaveClick,
      handleEditClick,
      handleDiscardButtonClick,
    ]
  );

  return (
    <>
      <div style={{ height: "90%", width: "100%" }}>
        <DataGrid
          rowBufferPx={20}
          rows={rows}
          columns={columns}
          apiRef={apiRef}
          disableRowSelectionOnClick
          checkboxSelection
          hideFooter
          density="compact"
          processRowUpdate={processRowUpdate}
          editMode="row"
          rowModesModel={rowModesModel}
          ignoreValueFormatterDuringExport
          getCellClassName={getCellClassName}
          sx={{
            "& .MuiDataGrid-cell--editing": {
              border: "2px solid #2e7d32",
              borderRadius: "4px",
              backgroundColor: "rgba(25, 118, 210, 0.1)",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            [`& .${gridClasses.row}.row--removed`]: {
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? "rgba(255, 170, 170, 0.3)"
                  : darken("rgba(255, 170, 170, 1)", 0.7),
            },
            [`& .${gridClasses.cell}.cell--edited`]: {
              backgroundColor: "rgba(255, 173, 96, 0.7)",
            },
            [`& .${gridClasses.row}.row--edited`]: {
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? "rgba(255, 254, 176, 0.3)"
                  : darken("rgba(255, 254, 176, 1)", 0.6),
            },
            width: "100%",
          }}
          loading={isSaving}
          getRowClassName={getRowClassName}
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={onRowSelectionToggle}
          slots={{
            toolbar: toolbar,
          }}
        />
      </div>
    </>
  );
};

export default SEBulkEditingDataGrid;
