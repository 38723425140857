// apiClient.ts
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

type BaseUrlFactory = () => Promise<string>;

export const constructHeader = () => {
  const hostname = window.location.origin;
  console.debug("Constructing header. Hostname:", hostname);

  if (hostname.includes("localhost")) {
    console.debug("Is localhost. Returning empty header.");
    return {};
  }

  const token = sessionStorage.getItem("token");
  const refreshToken = sessionStorage.getItem("refreshToken");
  console.debug("Token retrieved:", token ? "Yes" : "No");
  console.debug("Refresh token retrieved:", refreshToken ? "Yes" : "No");

  return {
    authorization: `Bearer ${token}`,
    refresh_token: refreshToken,
  };
};
class ApiClient {
  private axiosInstance: AxiosInstance;
  private baseUrlFactory: BaseUrlFactory;

  constructor(
    baseUrlFactory: BaseUrlFactory,
    includeRefreshToken: boolean = true
  ) {
    this.baseUrlFactory = baseUrlFactory;
    const headers = constructHeader();
    if (!includeRefreshToken) {
      delete headers.refresh_token;
    }
    this.axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
    });

    this.axiosInstance.interceptors.request.use(async (config) => {
      config.baseURL = await this.baseUrlFactory();
      return config;
    });
  }

  async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.get(
      url,
      config
    );
    return response.data;
  }

  async post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.post(
      url,
      data,
      config
    );
    return response.data;
  }

  async put<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.put(
      url,
      data,
      config
    );
    return response.data;
  }

  async patch<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.patch(
      url,
      data,
      config
    );
    return response.data;
  }

  async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.delete(
      url,
      config
    );
    return response.data;
  }
}

export const createApiClient = (
  baseUrlFactory: BaseUrlFactory,
  includeRefreshToken: boolean = true
) => new ApiClient(baseUrlFactory, includeRefreshToken);
